@import "../Styles/Global.scss";

.navbar {
  .navbar-wrapper {
    @include wrapperStyle();
    .navs {
      width: 100%;
      padding: 1rem 0rem;
      background: transparent;
      display: none;
      @include flexBeetwen();
      .navbar-left {
        @include flexStart();
        gap: 3rem;
        .navbar-logo {
          @include flexStart();
          gap: 0.2rem;
          img {
            width: 180px;
          }
        }
        .navbar-links {
          @include flexStart();
          gap: 1.5rem;
          list-style-type: none;
          li {
            .link {
              @include linkStyle();
              position: relative;
              &::after {
                content: "";
                width: 0;
              }
            }
            .active {
              opacity: 1;
              position: relative;
              &::after {
                transition: 0.3s ease-in-out;
                content: "";
                width: 100%;
                height: 2px;
                background: rgba(0, 0, 0, 0.7);
                position: absolute;
                bottom: -5px;
                left: 0;
              }
            }
          }
        }
      }
      .navbar-right {
        @include flexStart();
        .dropdown {
          position: fixed;
          top: 0;
          right: 5rem;
          z-index: 1001;
          button {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            position: relative;
            z-index: 1000;
            text-align: left;
            padding: 0.5rem 2rem;
            background: white;
            font-family: $myFont;
            font-size: 17px;
            outline: none;
            border: none;
            cursor: pointer;
            display: flex;
            gap: 2rem;
            justify-content: space-between;
            font-size: 17px;
            transition: 0.3s ease-in-out;
            .caret {
              transition: 0.3s ease all;
              &.caret-t {
                transform: rotate(180deg);
                transition: 0.3s ease all;
              }
            }
          }
          .language {
            list-style: none;
            transform: translateY(-100%);
            position: relative;
            z-index: 999;
            transition: 0.3s ease-in-out;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            padding: 0.5rem;
            background: #fff;
            li {
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 0.5rem;
              margin: 0.5rem 0;
              font-family: $myFont;
              font-size: 17px;
            }
            &.click {
              transform: translateY(0);
            }
          }
        }
      }

      .show-menu {
        display: none;
      }
      .menu-dropdown {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 102;
        padding: 2rem 1rem;
        display: none;
        .hide-menu {
          cursor: pointer;
        }
        ul {
          list-style: none;
          li {
            margin: 1rem 0;
            
            .link {
              text-decoration: none;
              color: rgba(0, 0, 0, 0.7);
              font-family: $myFont, Sans-serif;
              font-size: 16px;
              font-weight: normal;
              text-transform: capitalize;
              line-height: 19px;
              opacity: 0.8;
              letter-spacing: -0.48px;
              position: relative;
              &::after {
                content: "";
                width: 0;
              }
            }
            .active {
              color: black;
              opacity: 1;
              position: relative;
              &::after {
                transition: 0.3s ease-in-out;
                content: "";
                width: 100%;
                height: 2px;
                background: black;
                position: absolute;
                bottom: -5px;
                left: 0;
              }
            }
          }
        }
        .lang {
          li {
            display: flex;
            justify-content: start;
            gap: .5rem;
            &:nth-child(1) {
              margin: 0;
            }
            display: flex;
            align-items: center;
            font-family: $myFont, Sans-serif;
            font-size: 16px;
            font-weight: normal;
            text-transform: capitalize;
            line-height: 19px;
            opacity: 0.8;
            letter-spacing: -0.48px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .navbar {
    .navbar-wrapper {
      .navs {
        .navbar-left {
          .navbar-links {
            display: none;
          }
        }
        .navbar-right {
          .dropdown {
            display: none;
          }
        }
        .show-menu {
          display: block;
        }
        .menu-dropdown {
          @include flexStart();
          flex-direction: column;
          text-align: center;
          height: 100%;
          background: #f5f5f5;
        }
      }
    }
  }
}
@media screen and (max-width: "480px") {
  .navbar {
    .navbar-wrapper {
      .navs {
        .navbar-left {
          .navbar-links {
            display: none;
          }
        }
        
        .show-menu {
          display: block;
        }
      }
    }
  }
}
