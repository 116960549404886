@import "../Styles/Global.scss";
.footer {
  .footer-wrapper {
    @include wrapperStyle();
    .footer-cards {
      padding: 2rem 0 3rem 0;
      @include flexBeetwen();
      align-items: start;
      width: 100%;
      .footer-card {
        h4 {
          @include styleh4();
          margin: 0 0 1.5rem 0;
          opacity: 1;
        }
      }
      .card1 {
        width: 20%;
        .logo {
          img {
            width: 180px;
          }
        }
        .logo-text {
          p {
            margin-top: 2rem;
            @include stylep();
            line-height: 25px;
            opacity: 0.5;
          }
        }
      }
      .card2 {
        width: 10%;
        .footer-links {
          list-style: none;
          li {
            margin: 0.5rem 0;
            .link {
              @include linkStyle();
              transition: 0.3s ease-in-out;

              opacity: 1;
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
      .card3 {
        width: 20%;
        ul {
          list-style: none;
          li {
            @include stylep();
            margin: 0.5rem 0;
            opacity: 0.5;
          }
        }
      }
      .card4 {
        width: 35%;
        .btn-group {
          width: 100%;
          display: flex;
          justify-content: space-between;

          form {
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            background: #f5f5f5;
            border-radius: 3rem;
            .input {
              input {
                border: none;
                background: transparent;
                outline: none;
                font-size: clamp(15px, 4vw, 17px);
                font-family: $myFont;
              }
            }
            .btn {
              button {
                @include buttonStyle();
              }
            }
          }
        }
      }
    }
  }
  .boxes {
    padding: 1.5rem 0;
    background: rgba(0, 0, 0, 1);
    text-align: center;
    p {
      @include stylep();
      color: #f5f5f5;
    }
  }
}
@media screen and (max-width: "1100px") {
  .footer {
    .footer-wrapper {
      .footer-cards {
        flex-wrap: wrap;
        row-gap: 2rem;
        .card1,
        .card2,
        .card3,
        .card4 {
          width: 48%;
        }
      }
    }
  }
}
@media screen and (max-width: "768px") {
  .footer {
    .footer-wrapper {
      .footer-cards {
        padding-bottom: 2rem;
        flex-wrap: wrap;
        row-gap: 1rem;
        .card1 {
          .logo-text {
            p {
              margin-top: 0.5rem;
            }
          }
        }
        .card1,
        .card2,
        .card3 {
          width: 100%;
          h4 {
            margin-bottom: 0.5rem;
          }
        }

        .card4 {
          width: 100%;
          .btn-group {
            form {
              justify-content: space-between;
              padding: 0.6rem 0.5rem;
              .input {
                width: 60%;
                input {
                  width: 100%;
                }
              }
              .btn {
                width: 40%;
                button {
                  width: 100%;
                  padding: 0.5rem 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
