@import "../Styles/Global.scss";
.collection {
  .collection-wrapper {
    @include wrapperStyle();
    @include flexBeetwen();
    align-items: start;
    padding: 6rem 0;
    .left-box {
      width: 25%;
      .btn-dropdown {
        display: grid;
        position: relative;
        z-index: 1;
        background: white;
        .input {
          padding: 0rem 0 1rem 0;
          background: white;
          .input-group {
            background: red;
            @include flexBeetwen();
            padding: 0.4rem 1rem;
            background: rgb(226, 226, 226);
            border-radius: 1.8rem;
            input {
              border: none;
              background: transparent;
              width: 90%;
              outline: none;
              font-family: $myFont;
              font-size: 17px;
              &::placeholder {
                font-family: $myFont;
              }
            }
            .search {
              img {
                overflow: hidden;
                width: 24px;
              }
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              background: rgb(243, 37, 37);
              box-shadow: none;
              overflow: hidden;
              width: 40px;
              height: 40px;
              padding: 0.5rem;
              margin: 0;
              box-sizing: border-box;
            }
          }
        }
        .button {
          background: white;
          padding: 1rem 0;
          button {
            @include flexBeetwen();
            border: none;
            background: white;
            font-family: $myFont;
            font-size: clamp(16px, 4vw, 19px);
            box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            padding: 1rem 0.5rem;
            width: 100%;
            font-family: $bold;
            cursor: pointer;
          }
          .icon {
            transition: 0.2s ease-in-out;
            &.active {
              transform: rotate(90deg);
            }
          }
        }
        .collection {
          padding: 0rem 2rem;
          position: relative;
          transform: translateY(-100%);
          z-index: -1;
          transition: 0.2s ease-in-out;
          opacity: 0;
          &.active {
            transform: translateY(0);
            opacity: 1;
          }
          span {
            display: block;
            margin: 1rem 0;
            @include stylep();
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:hover {
              color: rgb(243, 37, 37);
            }
          }
        }
      }
    }
    .right-box {
      .collection-filter-container {
        @include flexBeetwen();
        align-items: start;
        margin-bottom: 2rem;
        .left-content {
          h1 {
            @include styleh1();
            margin-bottom: .5rem;;
            padding-top: 0;
            font-family: $bold;
            opacity: 0.6;
            margin: 0.3rem 0;
            line-height: 15px;
          }
          p {
            margin-top: 1rem;
            @include stylep();
            font-size: 14px;
          }
        }
        .right-content {
          position: relative;
          width: 20%;
          // display: flex;
          // flex-direction: column;
          button {
            width: 100%;
            border: none;
            font-family: $myFont;
            font-size: clamp(14px, 4vw, 15px);
            padding: 0.8rem 1.5rem;
            border-radius: 1.8rem;
            background: rgb(0, 0, 0);
            color: #f5f5f5;
            cursor: pointer;
            transition: 0.3s ease-in-out;
          }
          .select {
            position: absolute;
            width: 120%;
            right: 0;
            background: #fff;
            opacity: 0;
           
            transition: .5s ease-in-out;
            &.active{
              opacity: 1;
            // transition: .3s ease-in-out;

            }
             span{
              display: block;
               padding: .4rem .2rem;
              @include stylep();
              font-size: 15px;
              cursor: pointer;
              &:hover{
                background: rgb(226, 226, 226);
              }
             }
          }
        }
      }
      width: 70%;
      .cards {
        width: 100%;
        @include flexBeetwen();
        flex-wrap: wrap;
        column-gap: 2%;
        row-gap: 20px;
        .card {
          cursor: pointer;
          width: 23%;
          .img {
            height: 200px;
            width: 100%;
            border-radius: 1.2rem;
            overflow: hidden;
            img {
              width: 100%;
              object-fit: cover;
              height: 100%;
            }
          }
          p {
            @include stylep();
            font-weight: 900;
            opacity: 1;
            margin: 1rem 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover {
              color: red;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: "992px") {
  .collection {
    .collection-wrapper {
      .left-box {
        opacity: 1;
      }
      .right-box {
        .cards {
          width: 100%;
          @include flexStart();
          .card {
            cursor: pointer;
            width: 30%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: "768px") {
  .collection {
    .collection-wrapper {
      flex-wrap: wrap;
      padding: 2rem 0;
      .left-box {
        width: 100%;
        display: none;
      }
      .right-box {
        width: 100%;
        .cards {
          width: 100%;
          @include flexBeetwen();
          .card {
            cursor: pointer;
            width: 48%;
          }
        }
        .collection-filter-container{
          flex-direction: column;
          gap: 1rem;
          .left-content{
            opacity: 1;
          }
          .right-content{
            width: 100%;
            
            button{
              width: 100%;
            }
            .select{
              width: 100%;
              left: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: "480px") {
  .collection {
    .collection-wrapper {
      .left-box {
        width: 100%;
      }
      .right-box {
        .cards {
          width: 100%;
          @include flexBeetwen();
          gap: 0.7rem;
          .card {
            .img {
              border-radius: 0;
              height: 120px;
            }
            cursor: pointer;
            width: 48%;
            max-width: 400px;
          }
        }
      }
    }
  }
}
