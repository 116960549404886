* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
$pColor: red;
$colorLink: rgb(157, 157, 157);
$h1Color: "red";
$h2Color: "red";
$h3Color: "red";
$h4Color: "red";
$buttonColor: "red";
$bg1Color: "blue";
$bg2Color: "blue";
@font-face {
  font-family: "Montserrat";
  src: url("../../public/Fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "MontserratBold";
  src: url("../../public/Fonts/Montserrat-Bold.ttf");
}
$myFont: "Montserrat";
$bold:"MontserratBold";
@mixin wrapperStyle {
  margin: 0 auto;
  padding: auto;
  width: 95%;
  @media screen and (min-width:"1300px") {
    width: 1300px;
  }
}
@mixin flexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin flexBeetwen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin styleh1 {
}
@mixin styleh2 {
  font-family: $myFont, Sans-serif;
  font-size: clamp(28px, 4vw, 36px);
  font-weight: bold;
  text-transform: capitalize;
  line-height: 19px;
  margin: 2rem 0;
}
@mixin styleh3 {
}
@mixin styleh4 {
  font-family: $myFont, Sans-serif;
  font-size: clamp(15px, 4vw, 17px);
  font-weight: bold;
  text-transform: capitalize;
  line-height: 19px;
  opacity: 0.8;
}
@mixin stylep {
  font-family: $myFont, Sans-serif;
  font-size: clamp(15px, 4vw, 16px);
  font-weight: normal;
  text-transform: capitalize;
  line-height: 19px;
  opacity: 0.8;
}
@mixin buttonStyle {
  border: none;
  font-family: $myFont;
  font-size: clamp(14px, 4vw, 15px);
  padding: 0.8rem 1.5rem;
  border-radius: 1.8rem;
  background: rgb(243, 37, 37);
  color: #f5f5f5;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    background: black;
  }
}
@mixin linkStyle {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  font-family: $myFont, Sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  line-height: 19px;
  opacity: 0.8;
  letter-spacing: -0.48px;
}
