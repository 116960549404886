@import "../Styles/Global.scss";
.contact {
  position: relative;
  z-index: 90;
  .contact-wrapper {
    @include wrapperStyle();
    @include flexBeetwen();
    align-items: start;
    padding: 5rem 0;
    .content-left {
      width: 45%;
      h2 {
        @include styleh2();
        margin-bottom: 3rem;
      }
      form {
        .input,
        .number {
          input {
            overflow: hidden;
            border: none;
            background: #f5f5f5;
            border-radius: 2rem;
            padding: 1.2rem 2rem;
            font-family: $myFont;
            font-size: clamp(15px, 4vw, 17px);
            width: 90%;
            outline: none;
          }
        }
        .input,
        .number,
        .textarea,
        button {
          margin: 2rem 0;
        }
        .textarea {
          textarea {
            width: 90%;
            height: 280px;
            border: none;
            background: #f5f5f5;
            font-family: $myFont;
            font-size: clamp(15px, 4vw, 17px);
            outline: none;
            padding: 1rem 2rem;
            border-radius: 1.8rem;
          }
        }
        button {
          @include buttonStyle();
          padding: 1.2rem 2rem;
          width: 30%;
          margin: 0;
        }
      }
    }
    .content-right {
      width: 50%;
      .map {
        .leaflet-container {
          height: 720px;
        }
      }
    }
  }
  .info{
    @include wrapperStyle();
    @include flexBeetwen();
    padding: 2rem 2rem;
    margin-bottom: 3rem;
    .info-card{
        @include flexStart();
        gap: 1rem;
        .info-img{
            .icon{
                font-size: 2.5rem;
                transition: .3s ease-in-out;
                &:hover{
                    color: black !important;
                    cursor: pointer;
                }
            }
        }
        .info-text{
            p{
                @include stylep();
                span{
                    @include styleh4();
                    opacity: 1;
                }
            }
        }
    }
  }
}
@media screen and (max-width: "992px") {
  .contact {
    .contact-wrapper {
      flex-wrap: wrap;
      padding: 3rem .5rem;
      .content-left {
        width: 100%;
        order: 2;
        h2 {
          text-align: center;
        }
        form {
          .input,
          .number {
            input {
              width: 100%;
            }
          }
          .textarea {
            textarea {
              width: 100%;
            }
          }
          button {
            position: absolute;
            margin-top: 1rem;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .content-right {
        width: 100%;
        order: 1;
        .map {
          .leaflet-container {
            height: 400px;
          }
        }
      }
    }
    .info{
        flex-wrap: wrap;
        gap: 2rem;
    }
  }
}
@media screen and (max-width: "768px") {
    .contact {
      .contact-wrapper {
        flex-wrap: wrap;
        padding: 3rem 0rem;
        .content-left {
          width: 100%;
          order: 2;
          h2 {
            text-align: center;
            margin-bottom: 2rem;
          }
          form {
            .input,
            .number {
              input {
                padding: 1rem 1.5rem;
                border-radius: .5rem;
              }
            }
            .textarea {
              textarea {
                padding: .6rem 1.5rem;
                border-radius: .5rem;
              }
            }
            button {
              padding: .6rem 1.5rem;
              width: 40%;
            }
          }
        }
        .content-right {
          width: 100%;
          order: 1;
          .map {
            .leaflet-container {
              height: 400px;
            }
          }
        }
      }
    }
    .info{
        margin-bottom: 1rem !important;
        padding-bottom: 0rem !important;
        padding-top: 1rem !important;
        .info-card{
            gap: 1rem !important;
            .info-img{
                .icon{
                   font-size: 1.5rem !important;
                   
                }
            }
        }
    }
  }
