@import "../Styles/Global.scss";
.home {
  .home-wrapper {
    @include wrapperStyle();
    .winter-collection,
    .autumn-collection,
    .summer-collection {
      h2 {
        @include styleh2();
        text-align: center;
      }
      .cards {
        width: 100%;
        @include flexStart();

        flex-wrap: wrap;
        column-gap: 2%;
        row-gap: 20px;
        .card {
          cursor: pointer;
          width: 18%;
          .img {
            height: 160px;
            width: 100%;
            border-radius: 1.2rem;
            overflow: hidden;
            img {
              width: 100%;
              object-fit: cover;
              height: 100%;
            }
          }
          p {
            @include stylep();
            font-weight: 900;
            opacity: 1;
            margin: 1rem 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover {
              color: red;
            }
          }
        }
      }
    }
    .home-bg {
      width: 100%;
      @include flexStart();
      position: relative;
      background: rgb(70, 67, 60);
      border-radius: 1.8rem;
      height: 640px;
      overflow: hidden;
      .left-content {
        width: 100%;
        overflow: hidden;
        z-index: 4;
        img {
          width: 90%;
          object-fit: cover;
          transform: translate(-20%, 0%);
          height: 100%;
        }
      }
      .right-content {
        position: absolute;
        right: 0;
        top: 30%;
        z-index: 0;
        overflow: hidden;
        h1 {
          @include styleh1();
          margin: 0;
          font-size: 8rem;
          text-align: right !important;
          transform: translate(0%, 15%);
          color: #f5f5f5;
          text-transform: uppercase;
          font-family: $bold;
        }
        h2 {
          @include styleh1();
         

          text-align: right;
          color: #f5f5f5;
          font-size: 5.5rem;
          font-family: $bold;
        }
        h3 {
          @include styleh1();
          margin: 0;
          font-size: 5rem;
          text-align: right;
          color: #f5f5f5;
          font-weight: bold;
          font-family: $bold;
        }
      }
    }
    .elementor-section {
      @include flexStart();
      gap: 2rem;
      width: 100%;
      flex-wrap: wrap;
      margin: 1rem 0;
      padding: 2rem 0;
      .content {
        @include flexStart();
        gap: 1rem;
        width: 22%;
        .image{
            img{
                width: 4rem;
                
            }
        }
        .text {
          h4 {
            @include styleh4();
            margin: 0.5rem 0;
            opacity: 1;
          }
          p {
            @include stylep();
            margin: 0.5rem 0;
            opacity: 0.4;
            font-size: 14px;
          }
        }
      }
    }
    .media-section {
      @include flexBeetwen();
      border-radius: 1.8rem;
      margin: 12rem 0;
      position: relative;
      width: 100%;
      height: 300px;
      padding: 2rem 0;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-60%, -35%);
        width: 65%;
        top: 0;
      }
      .text-left-card {
        width: 25%;
        position: static;
        z-index: 6;
        text-align: right;
        h2 {
          @include styleh2();
          font-family: $bold;
          color: #fff;
          font-size: clamp(40px, 8vw, 65px);

        }
        p {
          @include stylep();
          text-transform: initial;
          color: #fff;
          font-size: clamp(30px, 4vw, 35px);
          line-height: 50px;
        }
      }
      .text-right-card {
        width: 38%;
        position: static;
        z-index: 6;
        h2 {
          @include styleh2();
          font-family: $bold;
          color: #fff;
          margin: 1rem 0;
          margin-bottom: 0;
          font-size: clamp(32px, 4vw, 45px);
          line-height: 40px;
        }
        p {
          @include stylep();
          line-height: 30px;
          color: #fff;
          margin: 1rem 0;
          margin-bottom: .5rem;
          width: 85%;
        }
        button {
          @include buttonStyle();
          font-family: $bold;
          color: #fff;
          background: white;
          color: rgb(54, 95, 93);
        }
      }
    }
    .media-section1 {
      background: rgb(48, 126, 122);
    }
    .media-section2 {
      background: rgb(135, 123, 170);
    }
    .media-section3 {
      background: rgb(58, 90, 76);
    }
  }
}
@media screen and (max-width: "992px") {
  .home {
    .home-wrapper {
      .elementor-section {
        flex-wrap: wrap;
        gap: 1rem;
        .content {
          width: 45%;
        }
      }
      .winter-collection,
      .autumn-collection,
      .summer-collection {
        .cards {
          width: 100%;
          @include flexStart();
          .card {
            cursor: pointer;
            width: 22%;
          }
        }
      }
      .media-section {
        @include flexBeetwen();
        border-radius: 1.8rem;
        margin: 6rem 0;
        position: relative;
        width: 100%;
        height: 260px;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-60%, -25%);
          width: 65%;
          top: 0;
        }
        .text-left-card {
          text-align: left;
          margin-left: 2rem;
        }
        .text-right-card {
          width: 38%;
          h2,
          p,
          button {
            margin: 1rem 0;
          }
          p {
            width: 95%;
          }
          button {
            margin: 0;
          }
         
        }
      }
    }
  }
}
@media screen and (max-width: "768px") {
  .home {
    .home-wrapper {
      .home-bg {
        height: 400px;
        .right-content {
          top: 20%;
          h1 {
            font-size: 60px;
            transform: translate(0, 15%);
          }
          h2 {
            font-size: 50px;
            margin-bottom: 2rem;
          }
          h3 {
            font-size: 30;
          }
        }
      }
      .winter-collection,
      .autumn-collection,
      .summer-collection {
        .cards {
          width: 100%;
          @include flexStart();
          .card {
            cursor: pointer;
            width: 30%;
          }
        }
      }
      .media-section {
        @include flexBeetwen();
        flex-wrap: wrap;
        align-items: start;
        align-content: end;
        border-radius: 1.8rem;
        margin: 5rem 0;
        position: relative;
        width: 100%;
        height: 480px;
        padding-bottom: 1rem;
        gap: 0rem;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -35%);
          width: 100%;
          top: 0;
          height: 240px;
        }
        .text-left-card {
          
         width: 100%;
         margin: 0 auto;
         text-align: center;
         h2, p{
            margin: .1rem 0;
         }
         
        }
        .text-right-card {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          h2,
          p,
          button {
            margin: .9rem 0;
          }
          h2{
            margin-top: 0;
            
          }
          
        }
      }
    }
  }
}
@media screen and (max-width: "592px") {
  .home {
    .home-wrapper {
      .winter-collection,
      .autumn-collection,
      .summer-collection {
        .cards {
          width: 100%;
          @include flexBeetwen();
          .card {
            cursor: pointer;
            width: 48%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: "480px") {
  .home {
    .home-wrapper {
      .home-bg {
        height: 220px;
        .left-content {
          img {
            height: 220px;
          }
        }
        .right-content {
          top: 50%;
          transform: translateY(-20%);
          h1 {
            font-size: 30px;
            transform: translateX(0);
            margin: 1rem 0;
          }
          h2 {
            font-size: 20px;
            margin: 0;
            margin: 1rem 0;
          }
          h3 {
            font-size: 16px;
          }
        }
      }
      .elementor-section {
        .content {
          width: 100%;
          margin: 0 auto;
          gap: 0.6rem;
          .image {
            img {
              width: 2.6rem;
            }
          }
        }
      }
      .winter-collection,
      .autumn-collection,
      .summer-collection {
        .cards {
          width: 100%;
          @include flexBeetwen();
          gap: 0.7rem;
          .card {
            .img {
              border-radius: 0;
              height: 120px;
            }
            cursor: pointer;
            width: 48%;
            max-width: 400px;
          }
        }
      }
    }
  }
}
