@import "../Styles/Global.scss";
.product-detail {
  .product-wrapper {
    @include wrapperStyle();
    .top-product {
        @include flexStart();
        gap: 4rem;
        margin: 3rem 0;
      .product-img {
        width: 60%;
        height: 560px;
        border-radius: .8rem;
        overflow: hidden;
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            opacity: 1;
        }
      }
      .product-description {
        width: 40%;
        h2{
            @include styleh2();
            font-family: $bold;
            margin: 2rem 0;
        }
        .responsive-table {
          width: 80%;
          margin: 0;
          padding: 0;
          border-collapse: collapse;
          border-spacing: 0;
          thead, tbody{
              tr {
                  td{
                      @include stylep();
                      color: rgb(110, 94, 74);
                      opacity: 1;
                      border: 1px solid rgb(173, 156, 134);
                      padding: 1rem;
                }
            }
          }
        }
        p{
            @include stylep();
            margin: 2rem 0;
        }
      }
    }
    .tabs{
        margin: 2rem 0;
        .tab-header{
            .tabPane, .review{
                width: 70%;
                margin: 0rem auto;
                h2{
                    @include styleh2();
                    font-size: clamp(18px, 4vw, 24px);
                    font-family: $bold;
                    opacity: .8;
                    margin: 2rem auto;
                    
                }
                h3{
                    @include styleh3();
                    font-size: clamp(16px, 4vw, 22px);
                    margin: 2rem 0;
                    font-family: $bold;
                    opacity: .6;
                }
                p{
                    @include stylep();
                    width: 90%;
                    line-height: 30px;
                    margin: 2rem 0;
                }
            }
            .review{
                .content-left {
                    width: 80%;
                    margin: 0 auto;
                    h2 {
                      @include styleh2();
                      margin-bottom: 3rem;
                    }
                    form {
                      .input,
                      .number {
                        input {
                          overflow: hidden;
                          border: none;
                          background: #f5f5f5;
                          border-radius: 2rem;
                          padding: 1.2rem 2rem;
                          font-family: $myFont;
                          font-size: clamp(15px, 4vw, 17px);
                          width: 100%;
                          outline: none;
                        }
                      }
                      .input,
                      .number,
                      .textarea,
                      button {
                        margin: 2rem 0;
                      }
                      .textarea {
                        textarea {
                          width: 100%;
                          height: 280px;
                          border: none;
                          background: #f5f5f5;
                          font-family: $myFont;
                          font-size: clamp(15px, 4vw, 17px);
                          outline: none;
                          padding: 1rem 2rem;
                          border-radius: 1.8rem;
                        }
                      }
                      button {
                        @include buttonStyle();
                        padding: 1.2rem 2rem;
                        width: 200px;
                        float: right;
                        margin: 0;
                      }
                    }
                  }
            }
        }
        .ant-tabs-tab-btn{
            font-size: clamp(15px, 4vw, 17px);
            color: rgb(110, 94, 74);
            font-family: $bold;
        }
        // .ant-tabs-content-holder{
          
        // }
    }
  }
}
@media screen and (max-width:"992px") {
    .product-detail{
        .product-wrapper{
            .top-product{
                flex-wrap: wrap;
                gap: 1rem;
                .product-img{
                    width: 100%;
                }
                .product-description{
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width:"768px") {
    .product-detail{
        .product-wrapper{
            .top-product{
                flex-wrap: wrap;
                gap: 1rem;
                margin: 1.5rem 0;
                .product-img{
                    width: 100%;
                    height: 320px;
                }
                .product-description{
                    width: 100%;
                    h2, p{
                        margin: 1.5rem 0;
                    }
                    .responsive-table{
                        width: 100%;
                    }
                }
            }
            .tabs{
                margin: 1rem 0;
                .tab-header{
                    .tabPane, .review{
                        width: 95%;
                        p{
                            width: 100%;
                        }
                        h2, h3, p{
                            margin: 1rem 0;
                        }
                    }
                    .review{
                        .content-left {
                            width: 100%;
                            
                            h2 {
                              
                              margin-bottom: 2rem;
                            }
                            form {
                              .input,
                              .number {
                                input {
                                  padding: 1rem 1.5rem;
                                  border-radius: .5rem;
                                }
                              }
                              .textarea {
                                textarea {
                                  padding: .6rem 1.5rem;
                                  border-radius: .5rem;
                                }
                              }
                              button {
                                padding: .6rem 1.5rem;
                                width: 180px;
                                float: left;
                                text-align: center;
                              }
                            }
                          }
                    }
                }
            }
        }
    }
}